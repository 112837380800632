import React, {useState, useEffect} from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { Table, Row, Col } from "react-bootstrap";
import CustomDateConverter from "../common/CustomDateConverter";
import { toast } from "react-toastify";
import SettingsLoader from "../common/SettingsLoader";
import { Link } from "react-router-dom";
import {convertDateIntoLocalDate} from "../common/Functions";
import {useParams} from "react-router-dom";
import { apiPath } from "../../config";

function KsConfigurations(props) {
    const {tab} = useParams();
    const {getIdTokenClaims} = useAuth0();
    const [sources, setSources] = useState([]);
    const [sourcesCon, setSourcesCon] = useState([]);
    const [isLoadingKSC, setIsLoadingKSC] = useState(false);
    const [updateSourceCon, setUpdateSourceCon] = useState([]);
    const [deleteSourceConName, setDeleteSourceConName] = useState("");
    const [isUpdateBoxCon, setIsUpdateBoxCon] = useState(false);

    const [deleteSourceConID, setDeleteSourceConID] = useState("");


    const fetchKcs = async () => {
        try {
          setIsLoadingKSC(true);
          // Get the ID token from Auth0
          const getidToken = await getIdTokenClaims();
          const idToken = getidToken.__raw;
           const options = {
             method: 'GET',
             headers: {
               Authorization: `Bearer ${idToken}`,
               'Content-Type': 'application/json'
             },
           };
          // console.log((await Auth.currentSession()).getIdToken().getJwtToken())
          const path = `/ksc_list`;
          const url = `${apiPath()}${path}`;
          const response = await fetch(url, options);
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const result = await response.json();
          //  console.log("ksc-", result);
          setSourcesCon(result);
          setIsLoadingKSC(false);
        } catch (error) {
          console.error("Error fetching data:", error);
          setIsLoadingKSC(false);
        }
      };

      const fetchSources = async () => {
        try {
          // Get the ID token from Auth0
          const getidToken = await getIdTokenClaims();
          const idToken = getidToken.__raw;
           const options = {
             method: 'GET',
             headers: {
               Authorization: `Bearer ${idToken}`,
               'Content-Type': 'application/json'
             },
           };
          // console.log((await Auth.currentSession()).getIdToken().getJwtToken())
          const path = `/ks_list`;
          const url = `${apiPath()}${path}`;
          const response = await fetch(url, options);
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const result = await response.json();
          // console.log("ks- ",result);
          setSources(result);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      const handleEditSourceCon = (e) => {
        e.preventDefault();
        const ksc_id = e.target.getAttribute("data-id");
        const getSourceCon = sourcesCon.filter((source) => source.ksc_id === parseInt(ksc_id)); // Assuming ksc_id is a number
        // console.log(getSourceCon);
        setUpdateSourceCon(getSourceCon[0]);
        setIsUpdateBoxCon(true);
      }

      const handleDeleteSourceConWarning = (e) => {
        e.preventDefault();
        const ksc_id = e.target.getAttribute("data-id");
        const ksc_name = e.target.getAttribute("data-name");
        setDeleteSourceConID(ksc_id);
        setDeleteSourceConName(ksc_name);
        document.getElementById("delete_sourceCon_modal_alert").classList.add("show");
      };

      const handleDeleteKSC = async (e) => {

        e.preventDefault();
        if (e.target.classList.contains("disabled")) {
            return null;
        }
        e.target.classList.add("disabled");
        try {
            const toastid = toast.loading("Please wait...", {
                position: toast.POSITION.BOTTOM_RIGHT
                })
            // Get the ID token from Auth0
            const getidToken = await getIdTokenClaims();
            const idToken = getidToken.__raw;
             const options = {
               method: 'DELETE',
               headers: {
                 Authorization: `Bearer ${idToken}`,
                 'Content-Type': 'application/json'
               },
             };
            const path = `/knowledge_source_configuration?kscid=${deleteSourceConID}`;
            const url = `${apiPath()}${path}`;
            const response = await fetch(url, options);
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const result = await response.json();
            // console.log(result);
            toast.update(toastid, {
                render: "Knowledge source configration deleted successfully!",
                type: "success",
                isLoading: false,
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 3000
            });
            document.getElementById("delete_sourceCon_modal_alert").classList.remove("show");
            e.target.classList.remove("disabled");
            setDeleteSourceConID("");
            setDeleteSourceConName("");
            fetchKcs();
        } catch (error) {
            console.error("Error fetching data:", error);
        }
      };
      const handleCancelDeleteKSC = (e) => {
        e.preventDefault();
        setDeleteSourceConID("");
        setDeleteSourceConName("");
        document.getElementById("delete_sourceCon_modal_alert").classList.remove("show");
      };
      useEffect(() => {
          if(sourcesCon.length === 0 && tab === "knowledge-source-configurations"){
            fetchKcs();
            fetchSources();
          }
      }, [tab]);
  return (
    <div className="settings-tabs">
      <div className="header-button-adjust">
        <Link to="/settings/ksc/create" className="request_btn">Add a knowledge source configration</Link>
      </div>
        <div className="table-box">
            <Table hover>
                <thead>
                <tr>
                    <th className="table-name-th">Name</th>
                    <th>Confidential</th>
                    <th>Knowledge Sources</th>
                    <th>Last updated</th>
                    <th className="table_action">Action</th>
                </tr>
                </thead>
                <tbody>
                {sourcesCon &&
                    sourcesCon.map((sourceCon, index) => (
                    <tr key={index}>
                        <td className="table-name-td">{sourceCon.name || "-"}</td>
                        <td align="left">
                            <div className="col-xs-12 custom-response-checkbox td-relative">
                            <label htmlFor={"s-confidential-"+index}>
                                <span className="checkbox-label"></span>
                                <input
                                type="checkbox"
                                name="confidential-check"
                                id={"s-confidential-"+index}
                                checked={sourceCon.confidential}
                                onChange={(e) => (e.target.checked)}
                                disabled
                                />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                        </td>
                        <td>
                        <ul className="insideTableList">
                            {sourceCon.kss && sourceCon.kss.map((ks, index) => (  
                                <li key={index}>{ks.name}</li>
                            ))} 
                        </ul>
                        </td>
                        <td>{convertDateIntoLocalDate(sourceCon.updated_at)}</td>
                        <td className="table_action">
                            {sourceCon.can_edit && (
                            <div>
                                <Link to={`/settings/ksc/update/${sourceCon.ksc_id}`} className="editSourceBtn"></Link>
                                <a href="#" className="delete-user-modal-btn delete_btn" data-id={sourceCon.ksc_id} data-name={sourceCon.name} onClick={(e)=> handleDeleteSourceConWarning(e)}></a>
                            </div>
                            )}
                        </td>
                    </tr>
                    ))}
                    {sourcesCon.length === 0 && !isLoadingKSC && (
                        <tr>
                            <td colSpan="5" align="center">No knowledge source configuration available</td>
                        </tr>
                    )}
                </tbody>
            </Table>
            {isLoadingKSC && (
                <SettingsLoader />
            )}
            <div className="table-footer">
                
            </div>
        </div>
        <div id="delete_sourceCon_modal_alert" className="delete-user-modal">
            <div className="delete-user-modal-content">
                <div className="delete-user-modal-body">
                    <p>Are you sure you want to delete this Knowledge Source Configuration?</p>
                    <h5>{deleteSourceConName}</h5>
                </div>
                <div className="delete-user-modal-footer">
                    <a href="#" className="delete-user-modal-btn cancel" onClick={(e)=>handleCancelDeleteKSC(e)}>Cancel</a>
                    <a href="#" className="delete-user-modal-btn done" onClick={(e)=>handleDeleteKSC(e)}>Delete</a>
                </div>
            </div>
        </div>
    </div>
    );
}

export default KsConfigurations;