import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import { useAuth0 } from '@auth0/auth0-react';
import SimpleHeader from "../components/SimpleHeader";
import Footer from "../components/Footer";
import QuestionHero from "../components/askquestion/QuestionHero";
import MainAppWrap from "../layout/MainAppWrap";
import FeedbackAndCopy from '../components/askquestion/FeedbackAndCopy';
import useAccessToken  from '../components/askquestion/useAccessToken';
import DOMPurify from 'dompurify';
import ept_bot_img from '../images/ept_bot.svg'
import Loader from '../components/common/Loader';
import LoaderWithText from '../components/common/LoaderWithText';
import { useForumList } from '../components/common/ForumListContext';
import { websocketPath } from '../config';
import {marked}  from "marked";
// import ReactDOMServer from 'react-dom/server';

// import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
// import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs'; 

const AskQuestionEptChat = () => {
  const { getIdTokenClaims, isLoading, user, isAuthenticated } = useAuth0();
  const {eptChannels} = useForumList();
  const [eptClientID, setEptClientID] = useState('ODY8mXQS82');
  // const accessToken = useAccessToken(eptClientID);
  const [inputMessage, setInputMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const websocketRef = useRef(null);
  const [conversation_id, setConversation_id] = useState('');
  const [response_id, setResponse_id] = useState(0);
  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(false);
  const [isChatLoading, setIsChatLoading] = useState(false); // Updated state for chat loading
  
  const [currentStreamId, setCurrentStreamId] = useState(null);
  const [chatLoadingText, setChatLoadingText] = useState([
    "Establishing secure connection...",
    "Initializing chat...",
    "Loading...",
    "Please wait...",
  ]);
  const [reconnectionLoading, setReconnectionLoading] = useState(false);
  const [currentDisplayText, setCurrentDisplayText] = useState('');
  const [isChatWindowLoading, setIsChatWindowLoading] = useState(true);
  const [defaultQustionBool, setDefaultQustionBool] = useState(true);

  const [selectedChannel, setSelectedChannel] = useState('');

  const [userToken, setUserToken] = useState('');


  const [autoScrollEnabled, setAutoScrollEnabled] = useState(true);

  const [isUserScroll, setIsUserScroll] = useState(true);

  const [noAccessMesasge, setNoAccessMesasge] = useState('');

      // useEffect(() => {
      //   const fetchKcs = async () => {
      //       try {
      //         animateLoadingTexts(setCurrentDisplayText, chatLoadingText);
      //         // Get the ID token from Auth0
      //         const getidToken = await getIdTokenClaims();
      //           const idToken = getidToken.__raw;
      //       //   console.log('idToken-', idToken);
      //       setUserToken(idToken);
          
      //         const options = {
      //           method: 'GET',
      //           headers: {
      //             Authorization: `Bearer ${idToken}`,
      //             'Content-Type': 'application/json'
      //           },
      //         };
          
      //         const url = 'https://api.ept.ai/v2/ksc_list';
      //         const response = await fetch(url, options);
      //         if (!response.ok) {
      //           throw new Error(`HTTP error! Status: ${response.status}`);
      //         }
      //         const result = await response.json();
      //       //   console.log('ksc-', result);
      //         setSelectKscID(result[0].ksc_id);
      //         setKscs(result);
      //       } catch (error) {
      //         console.error('Error fetching data:', error);
      //       }
      //     };
      //     if(!isLoading){
      //       fetchKcs();
      //     }
            
      //   }, [isLoading]);

    useEffect(() => {
        if (eptChannels.length > 0 && selectedChannel === '') {
        // console.log(kscs[0].ksc_id);
        // console.log(eptChannels);
        if(eptChannels === "access_denied"){
          setSelectedChannel("access_denied");
        }else {
          setSelectedChannel(
            {
              "channel":  eptChannels[0].channel,
              "name":  eptChannels[0].name,
              "url": eptChannels[0].url
            }
          );
        }
        }
    }, [eptChannels]);

  

  useEffect(() => {
    if (isLoading || !user ) {
      return;
    }
    if(selectedChannel === "access_denied"){
      console.log("Access Denied");
      setNoAccessMesasge("You don't have access to this feature. Please contact your administrator.");
      return;
    }
    // console.log('accessToken-', accessToken);
      if ( selectedChannel !== '' ) {
        
        connectWebSocket();
      }
  }, [isLoading, user, selectedChannel]);



  

    
  
  const connectWebSocket = async () => {
    try {
      const tokenClaims = await getIdTokenClaims();
      const idToken = tokenClaims.__raw;
      // console.log('idToken-', idToken);

      if (!idToken) {
        console.error("ID token is not available.");
        return;
      }
      // console.log('selectedChannel-', selectedChannel);
      const getWebsocketURL = websocketPath();
      const websocketUrl = `${getWebsocketURL}?access_token=${idToken}&user_access_token=${idToken}&client_key=${eptClientID}&channel_id=${selectedChannel.channel}`;
      // console.log('websocketUrl-', websocketUrl);
      const ws = new WebSocket(websocketUrl);

      ws.onopen = () => {
        setReconnectionLoading(false);
        setIsChatWindowLoading(false);
        setIsSendButtonDisabled(false);
        // auto focus on text area when chat window is loaded
        document.querySelector('textarea').focus();
        console.log("WebSocket connection established");
      };
      let response_id = '';
      let output_format = '';
      ws.onmessage = (event) => {
        const response = JSON.parse(event.data);
        // console.log(response);

        if(response.conversation_id){
          setConversation_id(response.conversation_id);
          // console.log("conversation ID:", response.conversation_id);
        }
        

        if (response.response_id) {
          response_id = response.response_id;
          setResponse_id(response_id);
          // console.log("response ID:", response_id);
        }

        if (response.output_format) {
          output_format = response.output_format;
          // console.log("output_format:", output_format);
        }
  
        if (response && response.text) {
          setMessages(prevMessages => {
            const sender = 'ept AI';
            const messageIndex = prevMessages.findIndex(m => m.response_id === response_id && m.sender === sender);
  
            if (messageIndex !== -1) {
              const updatedMessages = [...prevMessages];
              const updatedMessage = {
                ...updatedMessages[messageIndex],
                text: updatedMessages[messageIndex].text + response.text,
                response_id: response_id // Use the ref's current value
              };
              updatedMessages[messageIndex] = updatedMessage;
              return updatedMessages;
            } else {
              return [
                ...prevMessages,
                { text: response.text, sender: sender, streamId: response.streamId, response_id: response_id, isStreamTerminated: false, output_format: output_format}
              ];
            }
          });
        }
  
        if (response.action === "terminate_stream") {
          // setResponse_id(prev => prev + 1); // Correctly increment the response_id
          setMessages(prevMessages =>
            prevMessages.map(msg =>
              msg.response_id === response_id ? { ...msg, isStreamTerminated: true } : msg
            )
          );
          setIsChatLoading(false);
          setIsSendButtonDisabled(false);
          document.querySelector('textarea').focus();
          console.log("Stream terminated");
        }
  
        if (response.isFinalPart) {
          setCurrentStreamId(null);
        }

        if(response.feedback){
          setMessages(prevMessages =>
            prevMessages.map(msg =>
              msg.response_id === response_id ? { ...msg, feedback: response.feedback } : msg
            )
          );
        }
        
      };

      ws.onclose = () => {
        console.log("WebSocket connection closed");
        setIsChatLoading(false); // Ensure loading state is reset on close
      };

      ws.onerror = (error) => {
        console.error("WebSocket error:", error);
        setIsChatLoading(false); // Ensure loading state is reset on error
      };

      websocketRef.current = ws;
    } catch (error) {
      console.error("Error obtaining the access token or connecting:", error);
      setIsChatLoading(false); // Ensure loading state is reset on error
    }
  };

  const checkAndReconnectWebSocket = () => {
    if (websocketRef.current && websocketRef.current.readyState === WebSocket.CLOSED) {
      setReconnectionLoading(true);
      console.log("WebSocket is closed. Attempting to reconnect...");
      connectWebSocket(); // Make sure this is adapted to your context
    }
  };

  const sendMessage = (message) => {
    // Ensure message is a string before trying to trim it.
    // This handles cases where message might be undefined or not a string.
    const finalMessage = (typeof message === 'string' ? message.trim() : '') || inputMessage.trim();
  
    if (!finalMessage) {
      console.log("No message to send");
      return;
    }
  
    if (websocketRef.current && websocketRef.current.readyState === WebSocket.OPEN) {
      setDefaultQustionBool(false);
      setIsChatLoading(true);
      setIsSendButtonDisabled(true);
  
      let dataObject = {
        message: finalMessage, // Assuming finalMessage is defined elsewhere
        action: conversation_id === '' ? 'start_conversation' : 'conversation_message',
        
        // client_key: eptClientID, // Assuming eptClientID is defined elsewhere
        // ksc_id : selectKscID,// Assuming selectKscID is defined elsewhere
      };
      
      // Only add auth0_token, user_id, and ksc_id if conversation_id is empty
      if (conversation_id === '') {
        // dataObject.auth0_token = userToken; // Assuming userToken is defined elsewhere
        // dataObject.user_id = user.sub; // Assuming user.sub is defined elsewhere
      }else {
        dataObject.conversation_id = conversation_id;
        dataObject.response_id = response_id; // Assuming response_id is defined elsewhere
      }

      // console.log('dataObject:', dataObject);
  
      const data = JSON.stringify(dataObject);
      websocketRef.current.send(data);
      setMessages(prevMessages => [...prevMessages, { text: finalMessage, sender: 'user' }]);
      // Only clear inputMessage if no valid message was passed directly or if the direct message is used
      if (typeof message !== 'string' || message.trim() === '') {
        setInputMessage('');
      }
      setTimeout(() => {
        adjustTextareaHeight(document.getElementById('chat-input-id'));
      }, 0);
      setAutoScrollEnabled(true);

    } else {
      console.error("WebSocket is not open. Message not sent.");
      setReconnectionLoading(true);
    }
  };

  const sendFeedback = (response_id, isPositive) => {
    if (websocketRef.current && websocketRef.current.readyState === WebSocket.OPEN) {
        console.log('Sending feedback:', isPositive ? 'positive' : 'negative', 'for response ID:', response_id);
        try {
            const data = JSON.stringify({
                "action": "feedback",
                "is_positive": isPositive,
                "response_id": response_id
            });

            websocketRef.current.send(data);

            // Set up a listener for the server response specifically related to this feedback
            // const handleMessage = (event) => {
            //     const response = JSON.parse(event.data);
            //     console.log(response)
            //     // Check if the response is related to feedback
            //     if (response.action === 'feedback_response' && response.response_id === response_id) {
            //         // Handle feedback acknowledgment
            //         if (response.status === 'success') {
            //             console.log('Feedback accepted for response ID:', response_id);
            //         } else {
            //             console.error('Feedback was not accepted for response ID:', response_id);
            //         }

            //         // Remove this event listener once the response is received
            //         websocketRef.current.removeEventListener('message', handleMessage);
            //     }
            // };

            // // Add the event listener to the WebSocket
            // websocketRef.current.addEventListener('message', handleMessage);
        } catch (error) {
            console.error('Error sending feedback:', error);
        }
    } else {
        console.error('WebSocket is not open.');
    }
};

  
  
  

  // const cleanHtmlContent = (htmlContent) => {
  //   // Extract content within <body> tags if present
  //   const bodyContentMatch = htmlContent.match(/<body[^>]*>([\s\S]*?)<\/body>/i);
  //   if (bodyContentMatch && bodyContentMatch[1]) {
  //     htmlContent = bodyContentMatch[1];
  //   }
  
  //   // Further cleanups can be added here if needed
  
  //   return htmlContent;
  // }

  const cleanHtmlContent = (html, output_format) => {
    let cleanhtml = '';
    if(output_format === 'markdown'){
      cleanhtml = marked(html);
    }else{
      cleanhtml = html;
    }

    let sanitizedHtml = DOMPurify.sanitize(cleanhtml, { USE_PROFILES: { html: true } });
  
    // Enhanced to remove language specifier along with code block delimiters
    // This will remove instances like ```html or ```javascript along with the triple backticks
    sanitizedHtml = sanitizedHtml.replace(/```(\w+)?\s?/g, '');
  
    // Find anchor links and add target="_blank" attribute
    sanitizedHtml = sanitizedHtml.replace(/<a\s+(?:[^>]*?\s+)?href="([^"]*)"([^>]*?)>/gi, (match, href, rest) => {
      // Check if the link already has a target attribute
      if (!rest.includes('target=')) {
        return `<a href="${href}" target="_blank"${rest}>`;
      } else {
        return match; // Link already has a target attribute
      }
    });
  
    return sanitizedHtml;
  };


  const handleForumChange = (selectedOptions) => {
    console.log("selectedOptions:", selectedOptions);
    setSelectedChannel(selectedOptions);
    if (websocketRef.current) {
      websocketRef.current.close();
    }

    setTimeout(() => {
      setMessages([]);
    setConversation_id('');
    setResponse_id(0);
    // setIsChatWindowLoading(true);
    // setDefaultQustionBool(true);
    setIsSendButtonDisabled(false);
    setInputMessage('');
    checkAndReconnectWebSocket();
    }, 300);
    
    
    // stop the websocket connection
    
      // console.log("raw id: " + event.target.value);
      // Handle thread list update or any other logic here
  };

  // Helper function to adjust the textarea height
  const adjustTextareaHeight = (element) => {
      const chatBox = document.getElementById('chat-messages-wrap-id');
      const maxLines = 3;
      const lineHeight = 35; // Adjust this based on your CSS
      const baseHeight = 78; // Adjust this based on your CSS for one line height including padding

      // Directly reset the height to ensure scrollHeight reflects actual text height
      element.style.height = 'auto';

      // Force browser reflow/repaint
      element.offsetHeight; // This line is supposed to trigger reflow

      // Calculate the current number of lines, considering padding and border
      const actualHeight = Math.max(element.scrollHeight, baseHeight); // Use max to ensure at least base height is considered
      let lines = Math.ceil((actualHeight - (baseHeight - lineHeight)) / lineHeight);
      lines = Math.max(lines, 1); // Ensure there is at least one line
      lines = Math.min(lines, maxLines); // Do not exceed the maximum number of lines

      // Using a very short timeout to allow the browser to complete any pending layout calculations.
          // Remove previous height classes
          element.classList.remove('height-one-line', 'height-two-line', 'height-three-line');
          chatBox.classList.remove('height-one-line', 'height-two-line', 'height-three-line');
          element.style.height = '';

          // Add the class based on the current number of lines
          switch (lines) {
              case 1:
                  element.classList.add('height-one-line');
                  chatBox.classList.add('height-one-line');
                  break;
              case 2:
                  element.classList.add('height-two-line');
                  chatBox.classList.add('height-two-line');
                  break;
              case 3:
                  element.classList.add('height-three-line');
                  chatBox.classList.add('height-three-line');
                  break;
              default:
                  // No default action needed since we've handled all cases
                  break;
          }
  };

  const handleTextareaChange = (e) => {
    checkAndReconnectWebSocket(); // Check WebSocket status on text change
    setInputMessage(e.target.value); // Update the message state
  };
  
  const handleTextareaKeyPress = (e) => {
    // If Enter is pressed without holding Shift, send the message
    if (e.key === 'Enter' && !e.shiftKey) {
      sendMessage();
      e.preventDefault(); // Prevents the default action of inserting a new line
    }
    checkAndReconnectWebSocket(); // Check WebSocket status on key press
  };
  
  const animateLoadingTexts = (setCurrentText, chatLoadingText) => {
    let currentIndex = 0;
  
    const updateText = () => {
      if (currentIndex < chatLoadingText.length) {
        setCurrentText(chatLoadingText[currentIndex]);
        currentIndex++;
        if (currentIndex < chatLoadingText.length) {
          setTimeout(updateText, 1000); // Adjust time as needed
        }
      }
    };
  
    updateText(); // Start the animation
  }

  const handleDefaultQuestion = (e) => {
    checkAndReconnectWebSocket(); // Check WebSocket status on text change
    document.querySelector('.default-question').style.display = 'none';
    sendMessage(e.target.innerText);
  }    

  

   useEffect(() => {
    if (isChatWindowLoading) {
      return;
    }
    const chatBox = document.getElementById('chat-messages-wrap-id');
    
    const handleChatBoxScroll = () => {
    const chatBox = document.getElementById('chat-messages-wrap-id');
        if (!isUserScroll) {
            // Reset the flag to true for any future scroll events
            setIsUserScroll(true);
            return;
        }
        // Check if the user has scrolled up
        if (chatBox.scrollTop < chatBox.scrollHeight - chatBox.clientHeight) {
            // If the user has scrolled up, disable auto-scrolling
            setAutoScrollEnabled(false);
        } else {
            // If the user has scrolled to the bottom, enable auto-scrolling
            setAutoScrollEnabled(true);
        }
    }
    const chatInput = document.getElementById('chat-input-id');
    if(window.innerWidth <= 375){
      chatInput.addEventListener('blur', () => {
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 0);
      });

    }
        chatBox.addEventListener('scroll', handleChatBoxScroll);
   }, [isChatWindowLoading]);



  useEffect(() => {
    // console.log("Messages updated:", messages);
    // applySyntaxHighlighting();
    // scroll to bottom for id chat-messages-wrap-id
        
    if(autoScrollEnabled && !isChatWindowLoading) {
      setIsUserScroll(false);
      const chatBox = document.getElementById('chat-messages-wrap-id');
      chatBox.scrollTop = chatBox.scrollHeight;
    }
  }, [messages, isChatWindowLoading]);

  // const parseAndHighlightCode = (text) => {
  //   // Split the text by code block syntax, assuming ``` as the delimiter
  //   const parts = text.split(/(```\w*\n[\s\S]*?\n```)/).filter(Boolean); // Split and remove empty strings
  
  //   return parts.map((part, index) => {
  //     // Check if the part is a complete code block
  //     const codeMatch = part.match(/```(\w*)\n([\s\S]*?)\n```/);
  //     if (codeMatch) {
  //       const [, language, code] = codeMatch;
  //       return (
  //         <SyntaxHighlighter key={index} language={language || 'plaintext'} style={docco}>
  //           {code.trim()}
  //         </SyntaxHighlighter>
  //       );
  //     } else {
  //       // Check if the part is possibly an incomplete code block or normal text
  //       const possibleCodeBlock = part.match(/```(\w*)\n?([\s\S]*)/);
  //       if (possibleCodeBlock) {
  //         // If it looks like an incomplete code block, render it as plain text
  //         const [, , possibleCode] = possibleCodeBlock;
  //         return <pre key={index} style={{ whiteSpace: 'pre-wrap' }}>{possibleCode}</pre>;
  //       } else {
  //         // Return normal text as sanitized HTML
  //         return <span key={index} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(part) }} />;
  //       }
  //     }
  //   });
  // };
  
  if(noAccessMesasge !== ''){
    return (
      <MainAppWrap>
        <div className="main-content response-detail-page">
          <SimpleHeader />
          <Container fluid="xxl">
            <Row>
              <Col>
                <h3 className="main-error-message">{noAccessMesasge}</h3>
              </Col>
            </Row>
          </Container>
        </div>
      </MainAppWrap>
    )
  }

  return(
    isAuthenticated && (
    <MainAppWrap>
        <div className="main-content">
        <SimpleHeader />
        <div className="question-page gray_bg">
            <Container fluid className="">
                <Container fluid="xxl">
                    <Row>
                        <Col className="no-padding">
                            <QuestionHero
                                handleForumChange={handleForumChange}
                                eptChannels={eptChannels}
                                selectedChannel={selectedChannel}
                            />
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid="xxl">
                <Row>
                    <Col sm={12}>
                    <div className='chat-window-wrap'>
                      {isChatWindowLoading && (
                        <LoaderWithText class='chat-loading-wrap' text={currentDisplayText} />
                      )}
                      <div className='chat-messages-wrap height-one-line' id='chat-messages-wrap-id'>
                        <ul id="eptai-chat-messages">
                          
                          <li className={`bot-message ${isChatWindowLoading ? 'hidden':'fadeIn'}`}>
                            <div className='chat-user-image'>
                              <img className='profile-avatar' src={ept_bot_img} alt='EPTAI'/>
                            </div>
                            <div className='chat-message-wrap'>
                              <div className='message-sender'>ept AI</div>
                              <span>Hi, I'm ept AI. I'm here to help you with your questions. Type a question about your products here to get an AI-generated answer based on the selected knowledge sources</span>
                            </div>
                          </li>
                          
                          {messages.map((msg, index) => (
                            <li key={msg.response_id ? `${msg.response_id}` : index} 
                                className={msg.sender === 'user' ? 'user-message' : 'bot-message'}>
                              <div className='chat-user-image'>
                                <img
                                  className='profile-avatar'
                                  src={msg.sender === 'user' ? user.picture : ept_bot_img}
                                  alt={msg.sender === 'user' ? user.name : 'EPTAI'}
                                />
                              </div>
                              <div className='chat-message-wrap'>
                                <div className='message-sender'>{msg.sender === 'ept AI' ? 'ept AI' : 'You'}</div>
                                <div id={msg.response_id} dangerouslySetInnerHTML={{ __html: msg.sender === 'user' ? msg.text.replace(/\n/g, '<br>') : cleanHtmlContent(msg.text, msg.output_format) }}></div>
                                <div>{msg.isStreamTerminted}</div>
                                {msg.sender === 'ept AI' && msg.isStreamTerminated && (
                                  <FeedbackAndCopy
                                    response_id={msg.response_id}
                                    sendFeedback={sendFeedback}
                                    feedback={msg.feedback}
                                    detailLink={true}
                                  />
                                )}
                              </div>
                              
                            </li>
                          ))}
                        </ul>
                        {defaultQustionBool && (
                        <div className={`default-question ${isChatWindowLoading ? 'hidden' : 'fadeIn'}`}>
                          <li onClick={(e)=> handleDefaultQuestion(e)}>What are your products?</li>
                        </div>
                        )}
                        {isChatLoading && (
                          <Loader />
                        )}
                        {reconnectionLoading && (
                          <LoaderWithText class='reconnection-loading-wrap' text='Reconnecting, please wait...' />
                        )}
                      </div>
                      <div className={`chat-input-button-wrap ${isChatWindowLoading ? 'hidden' : 'fadeIn'}`}>                        
                        <textarea
                          value={inputMessage}
                          onChange={handleTextareaChange}
                          onKeyPress={handleTextareaKeyPress}
                          onInput={(e) => adjustTextareaHeight(e.target)}
                          placeholder="Type a message..."
                          disabled={isChatLoading}
                          className='height-one-line'
                          id='chat-input-id'
                        ></textarea>
                        <button className='ask-send-btn' onClick={sendMessage} disabled={isSendButtonDisabled || isChatLoading}></button>
                      </div>
                      
                    </div>
                    </Col>
                </Row>
            </Container>
        </div>
        <Footer />
        </div>
    </MainAppWrap>
    )
);
};

export default AskQuestionEptChat;
