import React from "react";
import Router from "./Router";
import { Auth0Provider } from "@auth0/auth0-react";
import { ForumListProvider } from "./components/common/ForumListContext";
import {auth0Domain, auth0ClientId} from "./config";
import { fromUnixTime } from "date-fns";

function App() {
  const client_id = auth0ClientId();
  const auth0_domain = auth0Domain();
  // console.log(client_id, auth0_domain);

  return (
    <Auth0Provider
      domain={auth0_domain}
      clientId={client_id}
      authorizationParams={{
        redirect_uri: window.location.origin,
        onRedirectCallback: window.location.pathname,
      }}
    >
      <ForumListProvider>
        <Router></Router>
      </ForumListProvider>
    </Auth0Provider>
  );
}

export default App;
