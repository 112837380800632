import React, { useEffect } from "react";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

function MainChart() {

    useEffect(() => {
        // Given JSON data
        const jsonData = [
            { "year": "2024", "month": "5", "day": "7", "activities_count": "2", "user_id": "1.0" },
            { "year": "2024", "month": "5", "day": "8", "activities_count": "2", "user_id": "1.0" },
            { "year": "2024", "month": "5", "day": "9", "activities_count": "8", "user_id": "1.0" },
            { "year": "2024", "month": "5", "day": "9", "activities_count": "2", "user_id": "572.0" },
            { "year": "2024", "month": "6", "day": "18", "activities_count": "4", "user_id": "1.0" },
            { "year": "2024", "month": "6", "day": "19", "activities_count": "2", "user_id": "1.0" }
        ];

        // Aggregate data by date and user
        const dataMap = jsonData.reduce((acc, item) => {
            const date = `${item.year}-${item.month.padStart(2, '0')}-${item.day.padStart(2, '0')}`;
            const count = parseInt(item.activities_count, 10);
            const userId = item.user_id;

            if (!acc[date]) {
                acc[date] = {};
            }
            if (!acc[date][userId]) {
                acc[date][userId] = 0;
            }
            acc[date][userId] += count;

            return acc;
        }, {});

        // Create labels and datasets
        const labels = Object.keys(dataMap);
        const datasets = [];
        const userColors = {};

        Object.keys(dataMap).forEach(date => {
            Object.keys(dataMap[date]).forEach(userId => {
                if (!userColors[userId]) {
                    userColors[userId] = `hsl(${Math.random() * 360}, 100%, 75%)`; // Random color for each user
                }
            });
        });

        Object.keys(dataMap).forEach(date => {
            Object.keys(dataMap[date]).forEach(userId => {
                let dataset = datasets.find(d => d.label === `User ${userId}`);
                if (!dataset) {
                    dataset = {
                        label: `User ${userId}`,
                        backgroundColor: userColors[userId],
                        data: Array(labels.length).fill(0)
                    };
                    datasets.push(dataset);
                }
                dataset.data[labels.indexOf(date)] = dataMap[date][userId];
            });
        });

        const ctx = document.getElementById('chart').getContext('2d');
        new Chart(ctx, {
            type: 'bar',
            data: {
                labels: labels,
                datasets: datasets
            },
            options: {
                scales: {
                    x: {
                        stacked: true // Stack the x-axis
                    },
                    y: {
                        stacked: true, // Stack the y-axis
                        beginAtZero: true
                    }
                }
            }
        });
    }, []);

    return (
        <div>
            <canvas id="chart"></canvas>
        </div>
    );
}

export default MainChart;
