import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import SettingsLoader from "../common/SettingsLoader";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import MainAppWrap from "../../layout/MainAppWrap";
import SimpleHeader from "../SimpleHeader";
import { toast, ToastContainer } from "react-toastify";
import { apiPath } from "../../config";
import { set } from "date-fns";
import CodeTabs from "./partials/CodeTabs";

function ChannelDetail() {
  const navigate = useNavigate();
  const { getIdTokenClaims, user } = useAuth0();
  const { channel_id } = useParams();
  const [channelDetails, setChannelDetails] = useState({});
  const [kscList, setKscList] = useState([]);
  const [channelLoading, setChannelLoading] = useState(true);

  const [editingName, setEditingName] = useState(false);
  const [editingKsc, setEditingKsc] = useState(false);
  const [newName, setNewName] = useState("");
  const [newType, setNewType] = useState("");
  const [newConfidential, setNewConfidential] = useState(false);
  const [newKsc, setNewKsc] = useState("");
  const [showSecret, setShowSecret] = useState(false);

  const [scriptCode, setScriptCode] = useState(`
  <script>
  (function() {
      window.eptAIConfig = { 
        accessToken: 'YOUR_ACCESS_TOKEN',
      };
      
      var script = document.createElement('script');
      script.src = 'https://assets.ept.ai/chat/v0/ept_chat_loader.bundle.js?v=1.0.0';
      script.async = true;
      document.body.appendChild(script);
  })();
  </script>
  `);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Copied to clipboard!", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };

  const toggleSecretVisibility = () => {
    setShowSecret(!showSecret);
  };

  const getChannel = async () => {
    try {
      // Get the ID token from Auth0
      setChannelLoading(true);
      const getidToken = await getIdTokenClaims();
      const idToken = getidToken.__raw;
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
      };
      // console.log((await Auth.currentSession()).getIdToken().getJwtToken())
      const path = `/channel?channel_id=${channel_id}`;
      const url = `${apiPath()}${path}`;
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      console.log(result);
      setChannelDetails(result);
      setNewName(result.name);
      setNewType(result.type);
      setNewConfidential(result.confidential);
      setNewKsc(result.ksc);
      setChannelLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setChannelLoading(false);
    }
  };
  const fetchKcs = async () => {
    try {
      // Get the ID token from Auth0
      const getidToken = await getIdTokenClaims();
      const idToken = getidToken.__raw;
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
      };
      // console.log((await Auth.currentSession()).getIdToken().getJwtToken())
      const path = `/ksc_list`;
      const url = `${apiPath()}${path}`;
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      //   console.log("ksc-", result);
      setKscList(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    if (!user) {
      return;
    }
    if (channel_id) {
      getChannel();
    }
    fetchKcs();
  }, [channel_id, user]);

  const handleInlineEdit = (type, value) => {
    // Function to validate URLs
    const isValidUrl = (url) => {
      // Regular expression for a valid URL
      const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
      return urlRegex.test(url);
    };

    const removeUrlParams = (url) => {
      const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/i;
      if (urlRegex.test(url)) {
        const urlObj = new URL(url);
        // Remove "https://" and trailing slashes from the origin
        const cleanedOrigin = urlObj.origin
          .replace(/^https?:\/\//, "")
          .replace(/\/+$/, "");
        return cleanedOrigin;
      }
      return url;
    };

    // e.target.classList.add("disabled");

    let data = {};
    if (type === "name") {
      data.name = value;
    }
    if (type === "ksc") {
      data.ksc_id = Number(value);
    }
    data = JSON.stringify(data);
    // console.log(data);
    UpdateChannel(data);
  };

  const UpdateChannel = async (data) => {
    try {
      const toastid = toast.loading("Please wait...", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      // Get the ID token from Auth0
      const getidToken = await getIdTokenClaims();
      const idToken = getidToken.__raw;
      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
        body: data,
      };
      // console.log((await Auth.currentSession()).getIdToken().getJwtToken())
      const path = `/channel?channel_id=${channel_id}`;
      const url = `${apiPath()}${path}`;
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      if (result.ksc) {
        setNewKsc(result.ksc);
      }
      console.log(result);
      toast.update(toastid, {
        render: "Knowledge source updated successfully!",
        type: "success",
        isLoading: false,
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      console.log(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    if (e.target.classList.contains("disabled")) {
      return null;
    }
    e.target.classList.add("disabled");
    try {
      const toastid = toast.loading("Please wait...", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      // Get the ID token from Auth0
      const getidToken = await getIdTokenClaims();
      const idToken = getidToken.__raw;
      const options = {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
      };
      const path = `/channel?channel_id=${channel_id}`;
      const url = `${apiPath()}${path}`;
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      console.log(result);
      toast.update(toastid, {
        render: "Channel deleted successfully!",
        type: "success",
        isLoading: false,
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      document
        .getElementById("delete_user_modal-alert")
        .classList.remove("show");
      e.target.classList.remove("disabled");
      // Redirect to the knowledge sources page
      setTimeout(() => {
        navigate("/settings/channels");
      }, 3200);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleDeleteWarning = (e) => {
    e.preventDefault();
    document.getElementById("delete_user_modal-alert").classList.add("show");
  };
  const handleCancelDeleteKS = (e) => {
    e.preventDefault();
    document.getElementById("delete_user_modal-alert").classList.remove("show");
  };

  const handleKSCChange = (value, name) => {
    console.log("name: ", name);
    setNewKsc({ name: name });
    setEditingKsc(false);
    handleInlineEdit("ksc", value);
  };
  return (
    <MainAppWrap>
      <div className="main-content mont-font response-detail-page">
        <SimpleHeader title="Channel Details" />
        <Container fluid="xxl" className="">
          {!channelLoading && (
            <Row>
              <Col>
                <div className="page-actions">
                  <Link
                    to={`/responses?filterChannels=${channelDetails.channel}`}
                    className="responses-link"
                  >
                    Responses
                  </Link>
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              {!channelLoading && (
                <div className="ks-detail-top">
                  <div className="response-details ks-details">
                    <ul>
                      <li>
                        <h3>Channel ID:</h3>
                        <p>{channelDetails.channel || "-"}</p>
                      </li>
                      <li className="editable-li">
                        <h3>Channel Name:</h3>
                        <div className="inline-edit-wrap">
                          {editingName ? (
                            <Form.Control
                              type="text"
                              value={newName}
                              onChange={(e) => setNewName(e.target.value)}
                              className="inline-edit-input"
                              autoFocus
                              placeholder="Enter new name"
                            />
                          ) : (
                            <p>{newName || "-"}</p>
                          )}
                          {!editingName && (
                            <div
                              className="inline-edit-btn"
                              onClick={() => setEditingName(true)}
                            ></div>
                          )}

                          {editingName && (
                            <div className="inline-edit-btns">
                              <button
                                className="btn btn-primary update-inline-edit"
                                onClick={() => {
                                  // Call API to update name
                                  handleInlineEdit("name", newName);
                                  setEditingName(false);
                                }}
                              ></button>
                              <button
                                className="btn btn-secondary cancel-inline-edit"
                                onClick={() => {
                                  setEditingName(false);
                                  setNewName(channelDetails.name);
                                }}
                              >
                                X
                              </button>
                            </div>
                          )}
                        </div>
                      </li>
                      <li>
                        <h3>Channel Type:</h3>
                        <p>{newType || "-"}</p>
                      </li>

                      <li className="editable-li">
                        <h3>Confidential</h3>
                        <p className="confidential-switch">
                          <input
                            type="checkbox"
                            id="confidentialSwitch"
                            className="form-check-input"
                            checked={newConfidential}
                            onChange={(e) => {
                              setNewConfidential(e.target.checked);
                              handleInlineEdit(
                                "confidential",
                                e.target.checked
                              );
                            }}
                          />
                          <label
                            htmlFor="confidentialSwitch"
                            className="toggle-label"
                          ></label>
                        </p>
                      </li>
                      <li>
                        <h3>URL:</h3>
                        <p>{channelDetails.url || "-"}</p>
                      </li>
                      <li className="editable-li">
                        <h3>KSC:</h3>
                        {!editingKsc && <p>{newKsc.name || "-"}</p>}
                        {editingKsc && (
                          // select ksc from dropdown list
                          <select
                            className="form-select"
                            value={newKsc}
                            onChange={(e) => {
                              const { value, options, selectedIndex } =
                                e.target;
                              const dataName =
                                options[selectedIndex].getAttribute(
                                  "data-name"
                                );
                              handleKSCChange(value, dataName);
                            }}
                          >
                            <option value="">Select KSC</option>
                            {kscList.map((ksc) => (
                              <option
                                key={ksc.ksc_id}
                                value={ksc.ksc_id}
                                data-name={ksc.name}
                              >
                                {ksc.name}
                              </option>
                            ))}
                          </select>
                        )}
                        {!editingKsc && (
                          <p>
                            {kscList.map((ksc) =>
                              ksc.ksc_id === newKsc ? ksc.name : ""
                            )}
                          </p>
                        )}
                        {!editingKsc && (
                          <div
                            className="inline-edit-btn"
                            onClick={() => setEditingKsc(true)}
                          ></div>
                        )}
                      </li>
                      <li>
                        <h3>Delete</h3>
                        <p>
                          <a
                            href="#"
                            className="delete-ks"
                            onClick={(e) => handleDeleteWarning(e)}
                          ></a>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
              {channelLoading && <SettingsLoader />}
            </Col>
          </Row>
        </Container>
        {channelDetails.client_id &&
          channelDetails.client_secret &&
          !channelLoading && (
            <Container fluid="xxl" className="">
              <Row>
                <Col>
                  <div className="response-details channel-client-id-secret">
                    <h3>Websocket details:</h3>
                    <h5>Client ID:</h5>
                    <Form.Group className="client-input-wrap">
                      <Form.Control
                        type="text"
                        value={channelDetails.client_id || "-"}
                        disabled
                      />
                      <Button
                        variant="outline-secondary client-copy-btn"
                        onClick={() =>
                          handleCopy(channelDetails.client_id || "-")
                        }
                      ></Button>
                    </Form.Group>

                    <h5>Client Secret:</h5>
                    <Form.Group className="client-input-wrap">
                      <Form.Control
                        type={showSecret ? "text" : "password"}
                        value={channelDetails.client_secret || "-"}
                        disabled
                      />
                      <Button
                        variant="outline-secondary"
                        onClick={toggleSecretVisibility}
                        className={`show-hide-btn ${
                          showSecret ? "show" : "hide"
                        }`}
                      ></Button>
                      <Button
                        variant="outline-secondary client-copy-btn"
                        onClick={() =>
                          handleCopy(channelDetails.client_secret || "-")
                        }
                      ></Button>
                    </Form.Group>
                    <div className="code-tabs-wrap">
                      <h3>Integration:</h3>
                      <h4>Access Token</h4>
                      <p>
                        An access token is essential for your application's
                        authentication when communicating with the ept chat API.
                        <br />
                        The following code snippets demonstrate how to obtain an
                        access token using the client credentials grant type.
                      </p>
                      <CodeTabs />
                      <h4>Initializing websocket connection</h4>
                      <p>
                        The following code snippet demonstrates how to initialize a websocket connection with ept AI.
                        </p>
                      <div className="script-code-wrap">
                        <pre>
                          <code>{scriptCode}</code>
                        </pre>
                        <Button
                          variant="outline-secondary client-copy-btn"
                          onClick={() => handleCopy(scriptCode)}
                        ></Button>
                      </div>
                      <div class="config-option">
                        <p>For more details please have a look at ept AI websocket <a href="https://github.com/EptAI/ept-ai-chat-app-sample" target="_blank">sample app</a></p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          )}
      </div>
      <ToastContainer />
      <div id="delete_user_modal-alert" className="delete-user-modal">
        <div className="delete-user-modal-content">
          <div className="delete-user-modal-body">
            <p>Are you sure you want to delete this Channel?</p>
            <h5>{newName}</h5>
          </div>
          <div className="delete-user-modal-footer">
            <a
              href="#"
              className="delete-user-modal-btn cancel"
              onClick={(e) => handleCancelDeleteKS(e)}
            >
              Cancel
            </a>
            <a
              href="#"
              className="delete-user-modal-btn done"
              onClick={(e) => handleDelete(e)}
            >
              Delete
            </a>
          </div>
        </div>
      </div>
    </MainAppWrap>
  );
}

export default ChannelDetail;
