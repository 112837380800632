import React, {useEffect, useState} from "react";
import LikeIcon from "../../images/like.svg";
import DislikeIcon from "../../images/dislike.svg";
import CopyIcon from "../../images/copy_new.svg";
import likedIcon from "../../images/like_source.svg";
import dislikedIcon from "../../images/dislike_source.svg";
import { useAuth0 } from "@auth0/auth0-react";
import { apiPath } from "../../config";
import { Link } from "react-router-dom";

function FeedbackAndCopy(props) {
    const { getIdTokenClaims } = useAuth0();
    const [copySuccess, setCopySuccess] = useState(false);

    const postCopyCount = async (res_id) => {
      try {
        console.log('res_id-', res_id);
        const getidToken = await getIdTokenClaims();
          const idToken = getidToken.__raw;
        //   console.log('idToken-', idToken);
      
          const options = {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${idToken}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              response_id:res_id
            })
          };
  
        const copyPath = `/response_copy`;
        const url = `${apiPath()}${copyPath}`;
        const response = await fetch(url, options);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const copyResult = await response.json();
        console.log(copyResult);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
    };

    const handleCopy = (e, id) => {
        e.preventDefault();
        const divId = id;
        const divElement = document.getElementById(divId);
        
      
        if (!divElement) {
          console.error(`Could not find element with ID '${divId}'`);
          return;
        }
      
        const range = document.createRange();
        range.selectNodeContents(divElement);
      
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
      
        try {
          document.execCommand('copy');
          // Text successfully copied to the clipboard
          setCopySuccess(true);
          postCopyCount(id);
          setTimeout(() => {
            setCopySuccess(false);
          }, 3000);
          console.log('Text copied:');
        } catch (error) {
          // Error occurred while copying
          console.error('Failed to copy text:', error);
        }
      
        selection.removeAllRanges();
      };

      useEffect(() => {
        if(props.feedback)
        {
            if(props.feedback.is_positive)
            {
                document.getElementById(`like_response_id_${props.response_id}`).src = likedIcon;
                document.getElementById(`dislike_response_id_${props.response_id}`).src = DislikeIcon;
            }
            if(!props.feedback.is_positive)
            {
                document.getElementById(`dislike_response_id_${props.response_id}`).src = dislikedIcon;
                document.getElementById(`like_response_id_${props.response_id}`).src = LikeIcon;
            }
        }
      }, [props.feedback]);

    return (
        <div className="feedback-copy">
            <a href="#" className="chat-copy-response" onClick={(e) => handleCopy(e, props.response_id)}>
                <img src={CopyIcon} alt="Copy" />
                {copySuccess && <div className="copy-text-success">Copied!</div>}
            </a>
            <a href="/" className="chat-feedback" onClick={(e) => {
                e.preventDefault(); // Prevent default anchor link behavior
                props.sendFeedback(props.response_id, true); // Send feedback with the response ID
            }}>
                <img id={`like_response_id_${props.response_id}`} src={props.currentRanking?.thumbs_up === 1 ? likedIcon : LikeIcon } alt="Like" />
            </a>
            <a href="#" className="chat-feedback" onClick={(e) => {
                e.preventDefault(); // Prevent default anchor link behavior
                props.sendFeedback(props.response_id, false); // Send feedback with the response ID
            }}>
                <img id={`dislike_response_id_${props.response_id}`}  src={props.currentRanking?.thumbs_down === 1 ? dislikedIcon : DislikeIcon} alt="Dislike" />
            </a>
            {props.detailLink && 
            <Link to={`/response/${props.response_id}`} target="_blank" className="chat-feedback-detail-btn">Response Details</Link>
            }
        </div>
    );
}

export default FeedbackAndCopy;